<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filter_draft_search"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filter_draft_search"
            v-model="tableDraft.filter.search"
            type="text"
            size="sm"
            placeholder="search here"
            debounce="1000"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="paymentRequests.selected.length > 0"
        cols="12"
        class="px-1 mb-2"
      >
        <b-button
          size="sm"
          variant="danger"
          :disabled="tableDraft.busy || busy"
          @click="onDiscardSelected"
        >
          Discard Selected
        </b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row
      v-if="currentTabIndex === 1"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableDraft"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :stacked="isMobile()"
          :per-page="tableDraft.perPage"
          :current-page="tableDraft.currentPage"
          :items="tableDraftProvider"
          :fields="tableDraft.fields"
          :sort-by.sync="tableDraft.sortBy"
          :sort-desc.sync="tableDraft.sortDesc"
          :sort-direction="tableDraft.sortDirection"
          :filter="tableDraft.filter"
          :filter-included-fields="tableDraft.filterOn"
          :busy="tableDraft.busy"
        >
          <template #cell(index)="row">
            {{ tableDraft.currentPage * tableDraft.perPage - tableDraft.perPage + (row.index + 1) }}
          </template>

          <template #head(selected)>
            <b-form-checkbox
              v-model="selected.allSelected"
              :indeterminate="selected.indeterminate"
              :disabled="Number(tableDraft.totalRows) === 0 || tableDraft.busy"
              size="lg"
              @change="onSelectAllTableRows"
            />
          </template>

          <template #cell(selected)="row">
            <b-form-checkbox
              v-model="paymentRequests.selected"
              :value="row.item.id"
              :disabled="tableDraft.busy"
              size="lg"
            />
          </template>

          <template #cell()="row">
            <div class="text-md-nowrap">
              {{ row.value }}
            </div>
          </template>

          <template #cell(amount)="row">
            <div class="text-md-nowrap text-md-right">
              {{ row.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                variant="success"
                :disabled="busy"
                @click="onEdit(row.item)"
              >
                Edit
              </b-button>
              <b-button
                size="sm"
                variant="outline-danger"
                :disabled="busy || paymentRequests.selected.length > 0"
                @click="onDiscard(row.item)"
              >
                Discard
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableDraft.perPage"
          size="sm"
          class="w-100 w-md-25"
          :options="tableDraft.pageOptions"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableDraft.currentPage"
          pills
          last-number
          first-number
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
          :total-rows="tableDraft.totalRows"
          :per-page="tableDraft.perPage"
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { UserExpensesPRDraftService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {

  name: 'UserExpensesPaymentRequestsDrafts',

  mixins: [formatter, misc],

  props: {
    busy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    currentTabIndex: {
      type: Number,
      default: () => {
        return 1
      }
    }
  },

  data () {
    return {
      selected: {
        allSelected: false,
        indeterminate: false
      },
      paymentRequests: {
        id: 0,
        selected: []
      },
      paymentRequest: {
        id: 0,
        selected: []
      },
      tableDraft: {
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'selected', class: 'text-center' },
          { mobile: 9, key: 'action', class: 'text-center' },
          { mobile: 2, key: 'created_at', label: 'Drafted At', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 3, key: 'bp_name', label: 'Business Partner' },
          { mobile: 4, key: 'customer_name', label: 'Cash Advance' },
          { mobile: 5, key: 'bp_transaction_type', label: 'Transaction Type' },
          { mobile: 6, key: 'mop', label: 'Mode of Payment' },
          { mobile: 7, key: 'amount', formatter: this.numberFormatter },
          { mobile: 8, key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {
    'paymentRequests.selected' (newValues) {
      if (newValues.length === 0) {
        this.selected.indeterminate = false
        this.selected.allSelected = false
      } else if (newValues.length === this.$refs.tableDraft?.localItems.length) {
        this.selected.indeterminate = false
        this.selected.allSelected = true
      } else {
        this.selected.indeterminate = true
        this.selected.allSelected = false
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableDraftProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableDraft.busy = true
      return await UserExpensesPRDraftService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tableDraft.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableDraft.busy = false
      })
    },

    // MULTIPLE
    onSelectAllTableRows (selected) {
      this.paymentRequests.selected = selected ? this.$refs.tableDraft.localItems.map(item => item.id) : []
    },

    onEdit (paymentRequest) {
      this.$emit('onEdit', {
        ...paymentRequest,
        is_drafting: true
      })
    },

    onDiscard (paymentRequest) {
      this.paymentRequest.selected = [paymentRequest.id]
      this.swalConfirm({
        html: '<h6>Discard Draft?</h6>',
        confirmButtonText: 'Discard',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: () => new Promise(resolve => {
          UserExpensesPRDraftService.delete(this.paymentRequest).then(
            ({ data: { message } }) => {
              this.paymentRequest.selected = []
              if (this.currentTabIndex === 1) {
                this.tableDraftRefresh()
              }
              this.swalSuccess(message)
              resolve(message)
            }
          ).catch(
            error => resolve(error.message)
          )
        })
      })
    },

    onDiscardSelected () {
      this.swalConfirm({
        html: '<h6>Discard Drafts?</h6>',
        confirmButtonText: 'Discard',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-sm btn-danger'
        },
        preConfirm: () => new Promise(resolve => {
          UserExpensesPRDraftService.delete(this.paymentRequests).then(
            ({ data: { message } }) => {
              this.paymentRequests.selected = []
              if (this.currentTabIndex === 1) {
                this.tableDraftRefresh()
              }
              this.swalSuccess(message)
              resolve(message)
            }
          ).catch(
            error => resolve(error.message)
          )
        })
      })
    },

    onPost (paymentRequest) {
      return new Promise((resolve, reject) => {
        UserExpensesPRDraftService.post(paymentRequest).then(({ data }) => {
          if (this.currentTabIndex === 1) {
            this.tableDraftRefresh()
          }
          resolve(data)
        }).catch(error => reject(error.message))
      })
    },

    onPut (paymentRequest) {
      return new Promise((resolve, reject) => {
        UserExpensesPRDraftService.put(paymentRequest).then(({ data }) => {
          if (this.currentTabIndex === 1) {
            const tableDraftRow = QUERY.find(
              this.$refs.tableDraft.localItems, {
                id: data.payment_draft.id
              }
            )
            tableDraftRow.id = data.payment_draft.id
            tableDraftRow.stage = data.payment_draft.stage
            tableDraftRow.bp_transaction_type = data.payment_draft.bp_transaction_type
            tableDraftRow.report_period = data.payment_draft.report_period
            tableDraftRow.budger_center = data.payment_draft.budger_center
            tableDraftRow.budget_center_id = data.payment_draft.budget_center_id
            tableDraftRow.item_center = data.payment_draft.item_center
            tableDraftRow.item_center_id = data.payment_draft.item_center_id
            tableDraftRow.particulars = data.payment_draft.particulars
            tableDraftRow.business_partner = data.payment_draft.business_partner
            tableDraftRow.business_partner_id = data.payment_draft.business_partner_id
            tableDraftRow.bp_business_tax = data.payment_draft.bp_business_tax
            tableDraftRow.bp_code = data.payment_draft.bp_code
            tableDraftRow.bp_customer_type = data.payment_draft.bp_customer_type
            tableDraftRow.bp_document_type = data.payment_draft.bp_document_type
            tableDraftRow.bp_name = data.payment_draft.bp_name
            tableDraftRow.bp_partner_type = data.payment_draft.bp_partner_type
            tableDraftRow.bp_tax_code = data.payment_draft.bp_tax_code
            tableDraftRow.bp_tax_status = data.payment_draft.bp_tax_status
            tableDraftRow.bp_tin = data.payment_draft.bp_tin
            tableDraftRow.bp_vendor_type = data.payment_draft.bp_vendor_type
            tableDraftRow.is_cash_advance = data.payment_draft.is_cash_advance
            tableDraftRow.customer = data.payment_draft.customer
            tableDraftRow.customer_id = data.payment_draft.customer_id
            tableDraftRow.customer_code = data.payment_draft.customer_code
            tableDraftRow.customer_name = data.payment_draft.customer_name
            tableDraftRow.customer_tin = data.payment_draft.customer_tin
            tableDraftRow.mop = data.payment_draft.mop
            tableDraftRow.branch = data.payment_draft.branch
            tableDraftRow.amount = data.payment_draft.amount
            tableDraftRow.invoice_amount = data.payment_draft.invoice_amount
            tableDraftRow.taxable_amount = data.payment_draft.taxable_amount
            tableDraftRow.withholding_tax = data.payment_draft.withholding_tax
            tableDraftRow.amount_due = data.payment_draft.amount_due
            tableDraftRow.for_deposit = data.payment_draft.for_deposit
            tableDraftRow.deposit_bank = data.payment_draft.deposit_bank
            tableDraftRow.deposit_account_number = data.payment_draft.deposit_account_number
            tableDraftRow.deposit_account_name = data.payment_draft.deposit_account_name
            tableDraftRow.approvals = data.payment_draft.approvals
            tableDraftRow.attachment_path = data.payment_draft.attachment_path
            tableDraftRow.attachments = data.payment_draft.attachments
            tableDraftRow.active = data.payment_draft.active
            tableDraftRow.created_at = data.payment_draft.created_at
            tableDraftRow.updated_at = data.payment_draft.updated_at
          }
          resolve(data)
        }).catch(error => reject(error.message))
      })
    },

    tableDraftRefresh () {
      if (this.currentTabIndex === 1) {
        this.$refs.tableDraft.refresh()
      }
    }
  }

}
</script>
